<template>
    <div class="container-fluid p-0 m-0 page pb-3" style="font-size: 0.8vw">
        <loading-modal 
            v-if="showModal"
            :title="modal.title" :message="modal.message"/>
        <!-- Cabeçalho da lista de OS: Logotipo, nome da empresa e botão de tela cheia -->
        <header class="d-flex align-items-center mb-1 px-2 pt-1" v-if="!$route.query.noHeader" style="height:4em;">
            <HorizontalLink style="position: absolute; font-size: 1.4em;"/>
            <strong class="text-center flex-grow-1">{{companyName}}</strong>
            <button 
                class="btn btn-secondary fullscreen-btn" 
                @click="goFullscreen" 
                style="position: absolute; right: 1vw;">
                <i class="fas" :class="{'fa-expand': !isFullscreen, 'fa-compress': isFullscreen}"></i>
            </button>
        </header>
        <!-- Corpo da lista de OS -->
        <div style="padding:0 0.5vw">   
            <table class="table">
                <thead class="text-center" style="width: 0.4vw; vertical-align: middle">
                    <tr>
                        <th style="width: 3vw">OS</th>
                        <th style="width: 5vw">Cartão</th>
                        <th style="width: 5.5vw">Placa</th>
                        <th style="width: 22vw">Modelo</th>
                        <th style="width: 8.5vw">Entrada</th>
                        <th style="width: 8.5vw">Previsão</th>
                        <th style="width: 3vw">Tempo (Acumulado)</th>
                        <th style="width: 7vw">Recurso</th>
                        <th>Descrição</th>
                    </tr>
                </thead>
                <tbody>
                    <OSListRow v-for="column in sortedArray" :key="column.id" :column="column"/>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import simulatorOSInjector from '../utils/SimulatorOSInjector'
import OSListRow from '../components/OSList/OSListRow.vue'
import LoadingModal from '../components/LoadingModal.vue'
import HorizontalLink from '../components/HorizontalLink.vue'
import WebSocketClient from '../classes/WebSocketClient'
import HandlersFactory from '../classes/backendHandlers/OSListHandlerFactory'
import SweetAlert from 'sweetalert2'

export default {
    name: 'OSList',
    data() {
        return {
            companyName: '',
            columns: [],
            backend: null,
            modal: {
                title: 'Conectando com o servidor',
                message: 'Aguarde...'
            },
            showModal: true,
            isFullscreen: false
        }
    },
    computed: {
        sortedArray() {
            return this.columns.map(e => e).sort((a, b) => a.expected_end_timestamp - b.expected_end_timestamp)        
        }
    },
    methods: {
        goFullscreen() {
            if (!this.isFullscreen)
                document.body.requestFullscreen()
                    .then(() => this.isFullscreen = true)
                    .catch(err => SweetAlert.fire({
                        title: 'Seu dispositivo não suporta tela cheia',
                        text: `Erro: ${err.message} (${err.name})`,
                        icon: 'info'
                    }))
            else
                document.exitFullscreen().then(() => this.isFullscreen = false)
        },
        onBackendConnect() {
            this.modal.title = 'Carregando lista de OS',
            this.modal.message = 'Aguarde...'
        },
        onBackendDisconnect(code) {
            if (code === 4006) {
                this.showModal = true
                this.modal.title = 'Há uma nova versão da lista de OS disponível ;)'
                this.modal.message = 'Por favor, atualize a página para receber a atualização'
                return false
            }
            if (code === 4000 || code === 4004 || code === 1002) {
                this.$router.replace('/')
                return false
            }

            this.showModal = true
            this.modal.title = 'Sem conexão com o servidor'
            this.modal.message = 'Não se preocupe. Reconectaremos assim que estiver tudo bem ;)'
            return true
        },
        connectToBackend() {
            this.backend = new WebSocketClient(this.onBackendConnect, this.onBackendDisconnect, `osListClient-${this.$route.params.code}`)
            this.backend.addHandlers(HandlersFactory(this))
        }
    },
    mounted() {
        if (this.$route.params.code == 'simul' && !this.$route.query.empty) {
            console.log('OSList de simulação')
            this.companyName = 'SIMULAÇÃO'
            Array.prototype.push.apply(this.columns, simulatorOSInjector())
            this.showModal = false
        }
        else {
            this.connectToBackend()
            this.showModal = false
        }
    },
    components: {OSListRow, HorizontalLink, LoadingModal}
}
</script>

<style scoped>
    .page {height: 100vh; background-color: #efefef; overflow-y: auto;}
    .header-image {height:30px}
    thead {background-color: #ccccff} 
    th {border: 0.01vw solid #a8a8a8; padding: 0.4vw}
    .fullscreen-btn {
        padding: 0.1em 0.5em;
        margin: 0;
        font-size: 2em;
        border-radius: 0.4em;
    }
</style>